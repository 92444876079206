import React, {useEffect, useState} from "react";
import Footer from "sections/Footer";
import HeroInterview from "sections/Hero/HeroInterview";

import dotsFaqLeft from "assets/svgs/faq-dots-left.svg";
import indexStyles from "../Index.module.scss";
import {BackgroundGradient, SeparatorBackground} from "../../shared/types";
import styles from "./Apply.module.scss";
import CardAbout from "../../components/Card/CardAbout";
import Metadata from "../../components/Metadata";
import Hotjar from "../../components/Hotjar";
import GoogleAnalytics from "../../components/GoogleAnalytics";
import {ViewportProvider} from "../../shared/context/ViewportContext";
import {CardRecruiter} from "../../components/Card/CardRecruiter/CardRecruiter";
import {JobPosition} from "../../components/JobPosition/JobPosition";
import {ApplyForm, DEFAULT_POSITION_ID} from "../../components/ApplyForm/ApplyForm";
import {getQueryParam} from "../../api/utils";
import {apiGet} from "../../api";
import {ApplySuccess} from "../../components/ApplySuccess/ApplySuccess";
import {skills} from "../../components/SkillsTree/SkillsTree";


enum ApplyState {
  INTRO,
  FORM,
  SUCCESS
}

const InterviewPage = () => {
  const [state, setState] = useState(ApplyState.INTRO);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [company, setCompany] = useState("");
  const [salaryRange, setSalaryRange] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [agent, setAgent] = useState(null);
  const [tags, setTags] = useState("");

  const fetchApplication = async () => {
    try {
      const {agent, position} = await apiGet("/application/get", {applicationId: getQueryParam("applicationId")});
      setState(ApplyState.SUCCESS);

      const {title, successMessage, company, salaryRange, tags} = position;
      setTitle(title);
      setSuccessMessage(successMessage);
      setCompany(company);
      setSalaryRange(salaryRange);
      setTags(tags);
      setAgent(agent);

      console.log();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (getQueryParam("applicationId")) {
      fetchApplication();
      return;
    }

    (async () => {
      try {
        const positionId = getQueryParam("position") || DEFAULT_POSITION_ID;
        const {title, description, company, salaryRange, tags} = await apiGet("/jobs/get", {positionId});
        setTitle(title);
        setDescription(description);
        setCompany(company);
        setSalaryRange(salaryRange);
        setTags(tags);

        if (positionId === DEFAULT_POSITION_ID) {
          setState(ApplyState.FORM);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [])

  return (
    <ViewportProvider>
      <Metadata />
      <Hotjar />
      <GoogleAnalytics />
      <HeroInterview title={title} />
      <div className={styles.mainContainer}>
        <CardAbout title={title} separatorBackground={SeparatorBackground.GradientMisc2}
                   className={styles.cardContainer}
                   header={<>
                     {salaryRange ? <div className={styles.headerLine}><div>Salary Range:</div><div>{salaryRange}</div></div> : null}
                     {company ? <div className={styles.headerLine}><div>Company:</div><a href={company}>{company}</a></div> : null}
                     {tags ? <div className={styles.tags}>{tags.split(/\r?\n/).map(t => {
                       const find = skills.find(({skills}) => skills.find(s => s.toLowerCase() === t.toLowerCase()));
                       let background = find?.background || BackgroundGradient.Gradient7;
                       return <div className={styles["tag" + background]}>{t}</div>
                     })}</div> : null}
                   </>}>
          {state === ApplyState.FORM ? <ApplyForm onSubmit={fetchApplication}/> : null}
          {state === ApplyState.INTRO ? <JobPosition description={description} onApply={() => setState(ApplyState.FORM)}/> : null}
          {state === ApplyState.SUCCESS ? <ApplySuccess agent={agent} description={successMessage} /> : null}
        </CardAbout>
      </div>
      <div className={indexStyles.sectionsFooter}>
        <img className={indexStyles.sectionsFooterDots} src={dotsFaqLeft} alt="" />
        <Footer />
      </div>
    </ViewportProvider>
  );
};

export default InterviewPage;
