import React from "react";
import styles from "./CardRecruiter.module.scss";


interface Props {
  name: string
  role: string
  phoneNumber: string
  email: string
  profileImageUrl: string
}

export const CardRecruiter = ({name, role, phoneNumber, email, profileImageUrl}: Props) => {
  return <div className={styles.card}>
    <div className={styles.imgContainer}>
      <img src={profileImageUrl} />
    </div>
    <div>
      <div className={styles.name}>{name}</div>
      <div className={styles.role}>{role}</div>
      <div className={styles.contact}>
        <a href={"tel:" + phoneNumber} >{phoneNumber}</a><a href={"mailto:" + email}>{email}</a>
      </div>
    </div>
  </div>

}
