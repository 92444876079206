import React from "react";
import Logo from "components/Logo";

import styles from "./HeroInterview.module.scss";

type Props = {
  title: String;
};

const HeroInterview: React.FC<Props> = ({ title }) => {
  return (
    <div className={styles.heroContainer}>
      <div className={styles.content}>
        <div className={styles.contentNavbarContainer}>
          <Logo />
        </div>
        <div className={styles.contentMain}>
          <div className={styles.textContainer}>
            <div className={styles.title}>{title}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroInterview;
