import React from "react";
import style from "../ApplyForm/ApplyForm.module.scss";
import ButtonPrimary from "../Button";
import {Markdown} from "../Markdown/Markdown";


interface Props {
  description: string
  onApply: () => any
}

export const JobPosition = ({description, onApply}: Props) => {
  return <>
    <Markdown>
      {description}
    </Markdown>
    <ButtonPrimary className={style.primaryButton} onClick={onApply}>
      Apply now
    </ButtonPrimary>
  </>;
}
