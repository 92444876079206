import React from "react";
import ReactMarkdown from "react-markdown";
import styles from "./Markdown.module.scss";


// Markdown format can be found here: https://commonmark.org/help/

export const Markdown = (props: any) => {
  return <ReactMarkdown {...props} className={styles.markdown} components={{
    a: ({node, ...props}) => <a {...props} target="_blank"/>
  }} />;
}
