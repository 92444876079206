import axios from "axios";
import {camelToSnake, snakeToCamel} from "./utils";

const BASE_URL = process.env.BACKEND_URL;

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiGet = (url: string, payload: any = {}) => {
  return <Promise<any>>api.get(`${url}?${(new URLSearchParams(camelToSnake(payload))).toString()}`);
};

export const apiPost = (url: string, payload: any = {}, config = {}) => {
  return <Promise<any>>api.post(url, payload, config);
};

api.interceptors.request.use(config => {
  if (config.data) {
    config.data = camelToSnake(config.data);
  }

  return config;
});

api.interceptors.response.use(
  response => {
    const data = snakeToCamel(response.data);

    if (data.data) {
      return data.data;
    }

    return data;
  },
  async error => {
    // 401 is invalid access. We try a refresh, update the tokens and retry the request.
    throw snakeToCamel(error);
  },
);
