import React from "react";
import {LoadingScrim} from "../LoadingScrim/LoadingScrim";
import {Markdown} from "../Markdown/Markdown";
import {CardRecruiter} from "../Card/CardRecruiter/CardRecruiter";


interface Props {
  description?: string
  agent?: any
}

export const ApplySuccess = ({description, agent}: Props) => {
  if (!description || !agent) {
    return <div style={{position: "relative", width: "100%", height: 400}}>
      <LoadingScrim />
    </div>
  }

  return <div>
    <Markdown>
      {mockPositionDescription(agent.name.split(" ")[0])}
    </Markdown>
    <CardRecruiter {...agent} />
  </div>;
}

const mockPositionDescription = (agentName: string) => `
### Thank you for submitting your application! 

${agentName} will be your recruiter who you can reach at any time for any questions.
`
