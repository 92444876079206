import React, {useEffect, useState} from "react";
import LabeledInput from "../LabeledInput";
import styles from "./ApplyForm.module.scss";
import ButtonPrimary from "../Button";
import {dataURItoBlob} from "../../utils";
import {DomInput} from "../DomInput";
import {LoadingScrim} from "../LoadingScrim/LoadingScrim";
import {getQueryParam, insertUrlParam} from "../../api/utils";


export enum FileType {
  PDF = "application/pdf",
}

enum ErrorMessage {
  TOO_BIG = "File exceeds 5MB",
  INVALID_TYPE = "Please upload a PDF file",
}

const ContactMethods = [{
  slug: "phone",
  text: "Phone call",
}, {
  slug: "whatsapp",
  text: "Whatsapp",
}, {
  slug: "linkedin",
  text: "LinkedIn",
}, {
  slug: "email",
  text: "Email",
}];

const STR_MAX_LEN = 64;

interface Props {
  onSubmit: () => void
}

export const DEFAULT_POSITION_ID = "936dadb4-12bd-4b21-9d76-256119b5404a";

export const ApplyForm = ({onSubmit}: Props) => {
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [linkedinError, setLinkedinError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [contact, setContact] = useState([]);
  const [contactError, setContactError] = useState("");
  const [fileName, setFileName] = useState("No file selected");
  const [formData, setFormData] = useState(null);
  const [resumeError, setResumeError] = useState("");
  const [loading, setLoading] = useState(false);
  const fileType = FileType.PDF;

  const positionId = getQueryParam("position") || DEFAULT_POSITION_ID;
  const agentId = getQueryParam("agent");

  useEffect(() => setEmailError(""), [email]);
  useEffect(() => setContactError(""), [contact]);
  useEffect(() => setLinkedinError(""), [linkedin]);
  useEffect(() => setPhoneError(""), [phone]);
  useEffect(() => setFullNameError(""), [fullName]);

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) {
      setFormData(null);
      return;
    }

    setFileName(uploadedFile.name);

    if (uploadedFile.size > 5 * 1024 * 1024) {
      setResumeError(ErrorMessage.TOO_BIG);
      setFormData(null);
      return;
    }

    if (fileType && uploadedFile.type !== fileType) {
      setResumeError(ErrorMessage.INVALID_TYPE);
      setFormData(null);
      return;
    }

    setResumeError("");

    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);

    reader.onload = (event) => {
      const fd = new FormData();
      const file = dataURItoBlob(event?.target?.result);
      fd.append("cv", file);

      setFormData(fd);
    };
  };

  const submit = () => {
    let error = false;
    if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,12}$/) || email.length > STR_MAX_LEN) {
      setEmailError("Invalid email");
      error = true;
    }
    if (fullName.length > STR_MAX_LEN) {
      setFullNameError("Invalid full name");
      error = true;
    }
    if (phone.length > STR_MAX_LEN / 2) {
      setPhoneError("Invalid phone number");
      error = true;
    }
    if (linkedin.length > STR_MAX_LEN * 2) {
      setLinkedinError("Invalid linked in profile url");
      error = true;
    }
    if (contact.length < 2) {
      setContactError("Please select at least 2 methods");
      error = true;
    }
    if (error) {
      return;
    }

    formData.delete("email");
    formData.append("email", email);
    formData.delete("name");
    formData.append("name", fullName);
    formData.delete("phone_number");
    formData.append("phone_number", phone);
    formData.delete("preferred_contact_methods");
    formData.append("preferred_contact_methods", JSON.stringify(contact));
    formData.delete("linkedin_url");
    formData.append("linkedin_url", linkedin);
    formData.delete("position_id");
    formData.append("position_id", positionId);
    formData.delete("agent_id");
    if (agentId) {
      formData.append("agent_id", agentId);
    }

    // TODO(@Denis): Perhaps add this.
    window.grecaptcha?.ready(() =>
      window.grecaptcha?.execute("6LelkxMiAAAAAFZbz_OKMmTuLwpLkxQpYRclPH_-", {action: "submit"}).then(async (token) => {
        try {
          setLoading(true);
          formData.delete("captcha");
          formData.append("captcha", token);
          const response = await fetch(process.env.BACKEND_URL + "application/apply/",{
            method: "POST",
            body: formData,
          });
          const data = await response.json();
          insertUrlParam("applicationId", data.application_id);
          onSubmit();
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      })
    );
  };

  const submitDisabled = !fullName || !email || !linkedin || !phone || !formData;

  return <div className={styles.form}>
    {loading ? <LoadingScrim /> : null}
    <div className={styles.flexContainer}>
      <LabeledInput label="Full name">
        <DomInput value={fullName} onChange={setFullName}/>
        {fullNameError && <div className={styles.error}>{fullNameError}</div>}
      </LabeledInput>
      <LabeledInput label="Email address">
        <DomInput value={email} onChange={setEmail}/>
        {emailError && <div className={styles.error}>{emailError}</div>}
      </LabeledInput>
    </div>
    <div className={styles.flexContainer}>
      <LabeledInput label="LinkedIn profile">
        <DomInput value={linkedin} onChange={setLinkedin}/>
        {linkedinError && <div className={styles.error}>{linkedinError}</div>}
      </LabeledInput>
      <LabeledInput label="Phone number">
        <DomInput value={phone} onChange={setPhone}/>
        {phoneError && <div className={styles.error}>{phoneError}</div>}
      </LabeledInput>
    </div>
    <LabeledInput label="Resume">
      <div className={styles.uploadInput}>
        <div>{fileName}</div>
        <input type="file" className={styles.fileUploadInput} onChange={handleFileChange}/>
        <ButtonPrimary className={styles.uploadButton}>Upload file</ButtonPrimary>
      </div>
      {resumeError && <div className={styles.error}>{resumeError}</div>}
    </LabeledInput>
    <LabeledInput label="Preferred contact method">
      <div className={styles.checkboxes}>
        {ContactMethods.map(({slug, text}) => <div className={styles.checkboxContainer}>
          <DomInput type="checkbox" id="webApp" checked={contact.indexOf(slug) !== -1} className={styles.checkbox}
                    onChange={() => contact.indexOf(slug) === -1 ?
                      setContact([...contact, slug]) : setContact(contact.filter(s => s !== slug))}/>
          <div className={styles.checkmark} />
          <div className={styles.labelText}>{text}</div>
        </div>)}
      </div>
      {contactError && <div className={styles.error}>{contactError}</div>}
    </LabeledInput>
    <ButtonPrimary onClick={submit} disabled={submitDisabled} className={styles.primaryButton}>
      Submit application
    </ButtonPrimary>
  </div>;
}
