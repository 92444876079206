export const camelToSnake: any = (obj: any) => {
  if (!obj) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(camelToSnake);
  }
  if (typeof obj === "string" || typeof obj === "number") {
    return obj;
  }
  const snakeObj: any = {};
  // @ts-ignore
  const snakeify = (str: string) => [...str.matchAll(/(^[a-z0-9]*)|([A-Z][a-z0-9]*)/g)]
    .map(([word]) => word.toLowerCase()).join("_");
  for (const [key, value] of Object.entries(obj)) {
    snakeObj[snakeify(key)] = Array.isArray(value) ? value.map(camelToSnake) :
      (typeof value === "object" ? camelToSnake(value) : value);
  }
  return snakeObj;
};

export const snakeToCamel: any = (obj: any) => {
  if (!obj) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(snakeToCamel);
  }
  if (typeof obj === "string" || typeof obj === "number") {
    return obj;
  }
  const camelObj: any = {};
  const camelify = (str: string): string => str.split("_").map((key, i) => i ? key[0].toUpperCase() + key.slice(1) : key).join("");
  for (const [key, value] of Object.entries(obj)) {
    camelObj[camelify(key)] = Array.isArray(value) ? value.map(snakeToCamel) :
      (typeof value === "object" ? snakeToCamel(value) : value);
  }
  return camelObj;
};

export const getQueryParam = (key: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
}

export const insertUrlParam = (key: string, value: any) => {
  if (history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.pushState({path: newurl}, "", newurl);
  }
}
