import React, {HTMLProps} from "react";
import {NOOP_FUNCTION} from "../../utils";

interface BaseInputProps<T=HTMLInputElement> extends Omit<HTMLProps<T>, "onChange"> {
  nodeType?: string
  onChange?: (v:string) => any
}

export const DomInput = ({nodeType = "input", onChange = NOOP_FUNCTION, ...otherProps}: BaseInputProps) => {
  return React.createElement(nodeType, {
    onChange: ({target}: any) => onChange(target.value),
    ...otherProps
  });
};
