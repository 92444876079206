import React, {HTMLProps, PropsWithChildren} from "react";
import styles from "./LabeledInput.module.scss";


interface Props extends HTMLProps<HTMLDivElement> {
  label: string
}

const LabeledInput = ({label, children, ...otherProps}: Props) => {
  return <div {...otherProps}>
    <label className={styles.label}>{label}</label>
    {children}
  </div>;
}

export default LabeledInput;
